@media print {
    @page {
        size: auto;
        margin: 2px;
    } 
    .hide-for-printing {
        display: none !important;
    }

    footer {
        display: none;
    }
}

header {
    
    .navbar-light {

        .nav-tabs {
            background-color: transparent;
            box-shadow: none;
            color: #555759;
            font-size: 14px;
            border: 0;

            a.nav-link {
                border: 0;
                text-transform: uppercase;
                background-color: transparent;
                color: #555759;
                font-weight: bold;
                padding-top: 0;
                padding-bottom: 8px;
                padding-right: 16px;
                padding-left: 16px;

                &.active {
                    border-bottom: 2px solid;
                    color: #0A3254;
                }

                &:hover {
                    color: #555759;
                }
            }
        }

    }
    
    nav.navbar {
        padding-left: 0;
        border-bottom: 1px var(--border-color) solid;
        padding-bottom: 0;
        padding:0;
        line-height: 41px;

        .navbar-toggler {
            border: 0;

            &:focus,
            &:hover {
                outline: none;
            }
        }

        .row {
            margin-left: 20px;
            margin-right: 5px;
        }

        .icons-right {
            padding-top: 12px;
            padding-right: 20px;
            flex-direction: row;

            .nav-item {
                padding-left: 12px;

                &:focus,
                button:focus {
                    outline:none;
                }

                @media (max-width: 576px) {
                    .material-icons {
                        font-size: 22px;
                    }

                    padding-left: 6px;
                }
            }

            .merchant-picker {
                background: none;
                padding-top:0;
                padding-right:0;
                margin-top: -8px;
                text-transform: none;

                .link-button {
                    color: #555759;
                }
            }

            
            .dropdown-menu.show {
                position: absolute;
            }

            #helpDropdown .material-icons:hover {
                color: #5A325F;
            }

            #userDropdown .material-icons:hover {
                color: #233D93;
            }
        }
    }

    margin-bottom: 20px;

    .navbar-brand {
        

        .header-brand-mini {
            height: auto;
        }

        .header-brand {
            display: inline-flex;
            align-items: center;

            .header-merchant {
                text-decoration: none;
                color: #000;
                font-weight: bold;
                padding-left: 15px;
                padding-right: 15px;
                max-width: 180px;
                text-wrap: wrap;
                text-align: center;
                line-height: 16px;
            }
    
            .header-logo {
                height: 38px;
            }

            .header-site-name {
                font-size: 14px;
                vertical-align: top;
                line-height: 16px;
            }
        }
    }
}

@media only screen and (max-width: 1440px) {
    header {
        .navbar-brand {
            
            margin-left: 8px;
            margin-right: 0;
            .header-brand {

                img.header-logo {
                    width: 64px;
                    max-width: 64px;
                    margin-right: 16px;
                }
            }
        }
    }
}

body,
body .ag-theme-alpine {
    font-family: 'Mulish', 'Arial', sans-serif;
    font-weight: medium;
    color: #333;

    //Remove minimum height for grids that use domLayout="autoHeight"
    .ag-center-cols-clipper {
        min-height: unset !important;
    }
}

:root {
    --link-color: #336094;
    --table-link-color: #336094;
    --gray-color: #77787A;
    --border-color: #E7E9E9;

    --success-color: #325837;
    --danger-color: #933;
    --error-color: #B2292E;
}

a, a:visited {
    color: var(--link-color);
}

label {
    font-weight: bold;
}

.table td a,
.table td a:visited,
.table td .btn.btn-link {
    color: var(--table-link-color);
}

#spinner {
    width: 65px;
    height: 65px;
}

.is-loading {
    background-color: rgba(0, 0, 0, 0.1);
}

.header-shadow {
    -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.5);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.5);
    z-index: 1200;
}

.layout-body-margin {
    margin-top: 15px;
    margin-left: 30px;
    margin-right: 30px;
}

.max-height {
    height: 100%;
}

.max-width {
    width: 100%;
}


.nav-item > .dropdown > a, 
.nav-item > .dropdown > .dropdown-menu > a {
    color: rgba(0, 0, 0, 0.87);
    font-weight: 400;
    font-size: 1rem;
}

.top-nav {
    margin:auto;
}

.danger-color {
    background-color: var(--danger-color) !important;
}

.red-text {
    font-size: .8rem;
    color: var(--danger-color) !important;
}


.clickable {
    cursor: pointer;
}

/* Toastr overrides */
.toast-info {
    background-color: #2A568F !important;
}

.toast-success {
    background-color: var(--success-color) !important;
}

.toast-warning {
    background-color: #F2AC25 !important;
}

.toast-error {
    background-color: var(--danger-color) !important;
}

.info-label {
    font-weight: 400;
    font-size: .7rem;
    color: rgba(0,0,0,.54);
    text-transform: uppercase;
}

.info-text {
    font-weight: 400;
    margin-top: -.3rem;
    margin-bottom: 0;
}

.opaque {
    opacity: 1;
}

textarea.form-control {
    padding-left: 1rem;
}

/* Div Tables - For when tables let you down */

.scroll-vertical { 
    overflow-y: auto 
}

.table {
    display: table;
    border-collapse: collapse;
    border-spacing: 0;
    border: 0;
    width: 100%;
    margin-bottom: 32px;
    margin-bottom: 2rem;
    
    .thead.tr {
        display: table-header-group;
    }

    .tr {
        display: table-row;
    }

    .td, .table .th {
        display: table-cell;
    }

    a.tr {
        color: inherit;
    }

    .th {
        border-top: none;
        vertical-align: bottom;
        border-bottom: 2px solid #dee2e6;
        font-weight: 500;
        font-size: .86rem;
        padding: .75rem;
        vertical-align: top;
        position: sticky;
        top: 0;
        background-color: white;
        z-index: 2;
    }

    .td {
        padding: .75rem;
        vertical-align: top;
        border-top: 1px solid #dee2e6;
        font-weight: 400;
        font-size: .93rem;
    }

    .td, 
    .th {
        padding-top: 1.1rem;
        padding-bottom: 1rem;
        padding: 8px 12px;
        padding: .5rem .75rem;
    }
    
    .th.sorting,
    .th.sorting .sort-icon {
        color: #000;
    }
}

.table-hover .tr:not(.thead):hover {
    background-color: #E5E8EE;
}


.sort-icon {
    font-size: 18px;
    vertical-align: top;
}


.table-fit {
    width: 1%;
}

/* Form inputs in tables */

.tr .form-control {
    padding: 0;
    margin: 0;
}

.tr .select-wrapper input.select-dropdown {
    height: 2.1rem;
}

.list-container {
    height: calc(100vh - 260px);
    max-height: calc(100vh - 260px);
    overflow-y: auto;
}

/* Button overrides */

.btn-circle {
    height: 30px;
    width: 30px;
    border-radius: 15px; 
    border: 2px solid #00235D;
}

/* Material Icons */

.material-icons.md-18 { font-size: 18px; }
.material-icons-outlined.md-18 { font-size: 18px; }

.material-icons {
    line-height: 24px;
}

.material-icons:hover {
    color: rgba(0, 0, 0, 0.87);
}

a.active {
    color: #4B738B;
}

/* Animations */

@keyframes fadeout {
    from {
        opacity: 100%;
    }
    50% {
        opacity: 0%;
    }
    100% {
        opacity: 0%;
        position: absolute;
        top: 0;
        left: 0;
        visibility: hidden;
    }
}

.fade-out-5s {
    animation-duration: 1s;
    animation-delay: 4s;
    animation-name: fadeout;
    animation-fill-mode: forwards;
}

/* Nav override */

.nav-pills {

    .nav-link,
    .show > .nav-link {
        color: #333;
        background-color: #E9EEF1;
        margin: 2px;
        text-transform: uppercase;
        border-radius: 3px;
        font-size: 12px;
        line-height: 14px;
        padding-left: 10px;
        padding-right: 10px;
        height: 31px;

        &.pending {
            background-color: #FFC10733;
        }

        .badge {
            background-color: #4B738B;
            color: #fff;
            font-weight: bold;
            margin-left: 8px;
            border-radius: 9px;
            padding: 4px;
            min-width: 18px;
        }

        &.disabled {
            color: #B0B3B3;
            background-color: var(--border-color);
            .badge {
                background-color: var(--gray-color);
            }
        }
    }
    
    .nav-link.active, 
    .show > .nav-link {
        color: #fff;
        background-color: #4B738B;

        
        .badge {
            background-color:#FFFFFF;
            color: #333333;
        }
    }
}

/* AG Grid overrides */

:root {
    --ag-header-background-color: white;
    --ag-odd-row-background-color: white ;
    --ag-background-color: #F9F9F9;
    --ag-border-color: var(--border-color);
    --ag-header-foreground-color: #333;
    --ag-control-panel-background-color: #fff;
    --ag-value-change-value-highlight-background-color: #287151;
    --ag-data-color: #333;
    --ag-foreground-color: #333;
}

.ag-theme-alpine {

    .ag-header {
        border-bottom: 0!important;
    }
    
    .ag-paging-panel {
        background-color: #F3F4F4;
        height: 30px
    }
    
    .ag-root-wrapper {
        border-radius: 2px;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }
    
    .ag-row,
    .ag-header-row {
        border-width: 0!important;
    }

    .ag-row {
        font-size: 12px;
    }

    .form-control {
        font-size: 12px;
    }
    
    .ag-cell,
    .ag-header-cell {
        line-height: 24px;
        padding-left: 4px;
        padding-right: 4px;
    }
     
    .ag-cell-wrapper {
        line-height: 24px;

        select.form-control {
            border:none;
            background: transparent;
            padding-top: 0;
            padding-left: 0;
            padding-bottom: 0;
            width: inherit;
            line-height:24px;
            height:24px;
        }

        select.form-control:focus {
            outline: none;
            background: transparent;
            box-shadow: none;
        }

        select.form-control:disabled {
            background: #e9ecef;
        }

        .number-editor input {
            background: transparent;
            border: none;
        }
    }
    
    .ag-row.ag-row-pinned {
        background-color: var(--ag-odd-row-background-color);
    }
    
    .ag-row .ag-cell,
    .ag-floating-top {
        border-bottom-width: 0!important;
    }

    
    a,
    a:visited,
    .btn.btn-link,
    .link-button {
        color: var(--table-link-color);

        &:hover {
            text-decoration: underline;
        }
    }

    .ag-cell-data-changed,
    .ag-cell-data-changed .link-button {
        color: white;
    }

    &.full-width {
        width: 100%;
    }

    .material-icons {
        font-size: 21px;
        vertical-align: bottom;
    }

    .disabled-control {
        pointer-events: none;
        opacity: 0.4;
    }

    /* Fix for AUT-2328 - Can be removed once ag-grid resolves issue AG-7242 */
    .ag-body-horizontal-scroll-viewport[style*='height: 0'] {
        display: none;
    }

    .ag-header-row .group-header {
        padding-left: 4px;
        padding-right: 4px;
        border-top-width: 0;

        .ag-header-group-cell-label { 
            justify-content: center;
            text-align: center;
        }
        
        .ag-header-group-text {
            background-color: #E6ECF2;
            padding: 2px 2px 2px 2px;
            text-transform: uppercase;
            font-size: 10px;

            width: 100%;
        }
    }
    .ag-header-row:not(:first-child) .ag-header-cell,
    .ag-header-row:not(:first-child) .ag-header-group-cell.ag-header-group-cell-with-group {
        border-top: 0;
    }
}

.ag-header-cell-text {
    font-size: 12px;
    line-height: 14px;
}

.page-header {
    font-size: 20px;
    font-weight: bold;
    line-height: 24px;
    padding-bottom: 12px;
}

.page-sub-header {
    font-size: 14px;
    font-weight: bold;
    padding-top: 6px;
}

.material-icons {
    color: var(--gray-color);
    opacity: 1;
}

.link-button {
    color: var(--link-color);
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: inline;
    margin: 0;
    padding: 0;
  }

.table .border-left {
    border-left: 1px solid #DDD;
}

.align-material-icon {
    line-height: 24px;
    display: inline-flex;
}

.btn-group.btn-group-toggle {
    .btn {
        background-color: #E9EEF1;
        margin-left: 4px;
        border-radius: 3.5px;
        font-size: 12px;
        font-weight: bold;

        &.active {
            background-color: #4B738B;
            color: #FFF;
        }

        &:first {
            margin-left: 0;
        }

        &:not(:last-child):not(.dropdown-toggle) {
            border-radius: 3.5px;
        }
    }
}

.error-list .error,
.error-text {
    color: var(--danger-color);
}

input.form-control,
input[type=date].form-control,
select.form-control {
    padding-left: 8px;
    padding-right: 8px;
}

.modal-content {
    padding: 20px;

    .modal-header {

        .modal-title {
            font-weight: bold;
        }
        padding: 0;
        padding-bottom: 8px;
    }

    .modal-body {
        padding: 0;
    }

    .modal-footer {
        padding: 0;
        padding-top: 12px;
    }
    
}

.form-control.is-valid, .was-validated   {

    .form-control {
        
        &:valid {
            padding-right: inherit;
            border-color: var(--border-color);
            background-image: none;
        }
    
        &:invalid,
        &.is-invalid,
        &.is-invalid:focus {
            padding-right: inherit;
            border-color: var(--error-color);
            border-width: 2px;
            background-color: #FCF6F6;
            background-image: none;
            box-shadow: none;
        }
    }
}

/*to fix the alignment issue when feedback and input group used together in NumberEditor.tsx-> https://github.com/twbs/bootstrap/issues/23454*/
.input-group.is-invalid {
    ~ .invalid-feedback {
      display: block;
    }
}

.hover-text {
    text-decoration: underline dotted;
}

/* Override checkbox/switch color */

.custom-control-input:focus ~  
.custom-control-label::before { 
    box-shadow: 
        0 0 0 0rem rgba(0, 0, 0, 0) !important; 
} 

.custom-control-input:checked ~  
.custom-control-label::before { 
    border-color: #336094 !important; 
    background-color: #336094 !important; 
} 

.custom-control-input:active ~  
.custom-control-label::before { 
    background-color: #336094 !important;
    border-color: #336094 !important; 
}

/* End checkbox/switch override */

.table-header-row {
    background-color: #F3F4F4;
    padding: 0;
    border: 1px solid var(--border-color);
    border-bottom: 0;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    margin-top: 12px;
  }

.btn {
    &.btn-primary{
        background-color: #336094 !important;
        
        &:hover {
            background-color: #26486f !important;
        }
    }
    &.btn-secondary,
    &.btn-secondary:hover {
        color: #333 !important;
    }

    &.btn-link {
        color: var(--link-color);
        font-size: 13px;
        font-weight: bold;
    }

    &.btn-outline-dark {
        color: #2C3940;
        border-color: #2C3940;

        &:hover {
            color: white;
        }
    }
}

a.no-link {
    color: inherit;
    
    &:hover {
        text-decoration: none;
    }
}

.btn-link.btn-link-normal {
    text-transform: none;
    color: var(--link-color);
}

.dropdown-menu {    
    box-shadow: 0 9px 13px #00000033;
}

.action-bar {
    text-align: right;
    padding-right: 0;
  
    .btn {
      font-size: 13px;
      font-weight: bold;
      padding-left: 0px;
      padding-right: 0;
      margin-left: 12px;
      margin-right: 12px;
  
      .material-icons {
        font-size: 21px;
        color: #4B738B;
        text-decoration: none;
        vertical-align: middle;
      }
  
      label {
        padding-left: 4px;
      }
    }

    .dropdown {
        display: inline-block;
    }
}

.form-group {
    label.form-label {
        &.optional {
            font-weight: inherit;
        }
    }
}